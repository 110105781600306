import React from 'react'
import styled from 'styled-components'
import { withPrefix, Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import InViewMonitor from 'react-inview-monitor'

import { connect } from 'react-redux'

import * as Layout from '../layouts/layout'
import * as FadeIn from './fadeIn'

const Footer = ({ lang }) => (
  <footer>
    <ContactSection>
      <Contact>
        <Layout.Container>
          <ContactRow>
            <ContactHeader>
              <FadeIn.Left>
                <h2>
                  {lang === 'ja' && 'お問い合わせはこちらから'}
                  {lang === 'en' && 'fjwofjweiojowe'}
                  {lang === 'zh' && '点击这里咨询'}
                </h2>
              </FadeIn.Left>

              <FadeIn.Left>
                <p>
                  {lang === 'ja' &&
                    '芙蓉苑に関するご意見・ご要望等ございましたらこちらからお問い合わせください。ご予約は各店舗へお電話ください。'}
                  {lang === 'en' && 'fjwofjweiojowe'}
                  {lang === 'zh' &&
                    '如果有关于芙蓉苑意见或者要求请在这里咨询。预约请直接联系各店铺。'}
                </p>
              </FadeIn.Left>
            </ContactHeader>
            <ContactButtons>
              <ContactButtonArea>
                <FadeIn.Up>
                  <p>
                    {lang === 'ja' &&
                      '芙蓉苑グループへのご意見・ご要望はこちら'}
                    {lang === 'en' && 'fjwofjweiojowe'}
                    {lang === 'zh' && '对芙蓉苑集团的意见・要求点击这里'}
                  </p>
                  <ContactButtonContact to="/contact">
                    {lang === 'ja' && 'お問い合わせ'}
                    {lang === 'en' && 'fjwofjweiojowe'}
                    {lang === 'zh' && '咨询'}
                  </ContactButtonContact>
                </FadeIn.Up>
              </ContactButtonArea>
              <ContactButtonArea>
                <FadeIn.Up>
                  <p>
                    {lang === 'ja' && '各店舗へのご予約・お問い合わせ'}
                    {lang === 'en' && 'fjwofjweiojowe'}
                    {lang === 'zh' && '各店铺的预约・咨询'}
                  </p>
                  <ContactButtonStore to="/stores">
                    {lang === 'ja' && '店舗一覧'}
                    {lang === 'en' && 'fjwofjweiojowe'}
                    {lang === 'zh' && '店铺内容'}
                  </ContactButtonStore>
                </FadeIn.Up>
              </ContactButtonArea>
            </ContactButtons>
          </ContactRow>
        </Layout.Container>
      </Contact>
    </ContactSection>
    <Nav>
      <Layout.Container>
        <NavRow>
          <Layout.Col tablet={3}>
            <NavMenu>
              <FadeIn.Left>
                <h3>Main menu</h3>
              </FadeIn.Left>

              <FadeIn.Left>
                <ul>
                  {/* <li>
                      <NavMenuLink to="/news">ニュース</NavMenuLink>
                    </li> */}
                  <li>
                    <NavMenuLink to="/concept">
                      {lang === 'ja' && '芙蓉苑のこだわり'}
                      {lang === 'en' && 'Stores'}
                      {lang === 'zh' && '芙蓉苑的观念'}
                    </NavMenuLink>
                  </li>
                  <li>
                    <NavMenuLink to="/stores">
                      {lang === 'ja' && '店舗一覧'}
                      {lang === 'en' && 'Stores'}
                      {lang === 'zh' && '店铺内容'}
                    </NavMenuLink>
                  </li>
                  {/* <li>
                      <NavMenuLink to="">芙蓉苑の料理人</NavMenuLink>
                    </li> */}
                  <li>
                    <NavMenuLink to="/recruit">
                      {lang === 'ja' && 'スタッフ募集'}
                      {lang === 'en' && 'Stores'}
                      {lang === 'zh' && '招募店员'}
                    </NavMenuLink>
                  </li>
                </ul>
              </FadeIn.Left>
            </NavMenu>
          </Layout.Col>
          <Layout.Col tablet={3}>
            <NavMenu>
              <FadeIn.Left>
                <h3>Sub menu</h3>
              </FadeIn.Left>

              <FadeIn.Left>
                <ul>
                  <li>
                    <NavMenuLink to="/contact">
                      {lang === 'ja' && 'お問い合わせ'}
                      {lang === 'en' && 'Stores'}
                      {lang === 'zh' && '咨询'}
                    </NavMenuLink>
                  </li>
                  <li>
                    <NavMenuLink to="/company">
                      {lang === 'ja' && '会社概要'}
                      {lang === 'en' && 'Stores'}
                      {lang === 'zh' && '公司简介'}
                    </NavMenuLink>
                  </li>
                  {/* <li>
                      <NavMenuLink to="">プライバシーポリシー</NavMenuLink>
                    </li> */}
                </ul>
              </FadeIn.Left>
            </NavMenu>
          </Layout.Col>
          {/* <Layout.Col tablet={6}>
              <NavMenu>
                <FadeIn.Left>
                  <h3>Stores</h3>
                </FadeIn.Left>

                <FadeIn.Left>
                  <NavStoresLink to="">芙蓉苑{' '}本店</NavStoresLink>
                </FadeIn.Left>

                <FadeIn.Left>
                  <NavStoresLink to="">火鍋城{' '}長堀橋店</NavStoresLink>
                </FadeIn.Left>

                <FadeIn.Left>
                  <NavStoresLink to="">芙蓉麻婆麺{' '}十三店</NavStoresLink>
                </FadeIn.Left>

                <FadeIn.Left>
                  <NavStoresLink to="">芙蓉麻婆麺{' '}三ノ宮店</NavStoresLink>
                </FadeIn.Left>

                <FadeIn.Left>
                  <NavStoresLink to="">火鍋城{' '}京都店</NavStoresLink>
                </FadeIn.Left>
              </NavMenu>
            </Layout.Col> */}
        </NavRow>
        <LogoRow>
          <Layout.Col tablet={4}>
            <FadeIn.Up>
              <Link to="/">
                <Logo>
                  <img src={withPrefix('/img/logo-pink.png')} alt="芙蓉苑" />
                </Logo>
              </Link>
            </FadeIn.Up>
          </Layout.Col>
          <Layout.Col tablet={4}>
            <FadeIn.Up>
              <Link to="/">
                <LogoFire>
                  <img src={withPrefix('/img/logo-fire2.png')} alt="芙蓉苑 火鍋城" />
                </LogoFire>
              </Link>
            </FadeIn.Up>
          </Layout.Col>
          <Layout.Col tablet={4}>
            <FadeIn.Up>
              <Link to="/">
                <LogoMabo>
                  <img src={withPrefix('/img/logo-mabo.png')} alt="芙蓉苑" />
                </LogoMabo>
              </Link>
            </FadeIn.Up>
          </Layout.Col>
        </LogoRow>
        <LogoRow style={{ margin: '2rem 0', justifyContent: 'flex-start' }}>
          {/* <Layout.Col tablet={4}>
            <FadeIn.Up>
              <a
                href="https://fuyoen-rinku.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoRinkuu>
                  <img src={withPrefix('/img/logo-rinkuu.png')} alt="芙蓉苑" />
                </LogoRinkuu>
              </a>
            </FadeIn.Up>
          </Layout.Col> */}
          <Layout.Col tablet={4}>
            <FadeIn.Up>
              <span>
                <LogoRinkuu>
                  <img src={withPrefix('/img/logo-fuyoen-entame.png')} alt="芙蓉苑エンタメ酒場" />
                </LogoRinkuu>
              </span>
            </FadeIn.Up>
          </Layout.Col>
          <Layout.Col tablet={4}>
            <FadeIn.Up>
              <span>
                <LogoSomeck>
                  <img src={withPrefix('/img/logo-someck.png')} alt="Someck" />
                </LogoSomeck>
              </span>
            </FadeIn.Up>
          </Layout.Col>
        </LogoRow>
      </Layout.Container>
    </Nav>
    <Copyright>
      <Layout.Container>
        <CopyrightRow>
          <CopyrightSns>
            <p>Follow Me</p>
            <Icons>
              <a
                href="https://www.facebook.com/FUYOEN/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </a>
              <a
                href="https://www.instagram.com/fuyoen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
              <a
                href="https://twitter.com/fuyoenosaka"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} />
              </a>
              <a
                href="https://page.line.me/aiy6070n"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={['fab', 'line']} />
              </a>
            </Icons>
          </CopyrightSns>
          <CopyrightTxt>© TryHard DINNING</CopyrightTxt>
        </CopyrightRow>
      </Layout.Container>
    </Copyright>
  </footer>
)

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Footer)

const pink = '#e3007f'
const beige = '#fadcbe'
const lightBeige = '#FEF8F2'
const brown = '#603813'

const Contact = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(
      -30deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    url(/img/contact.jpg) center/cover;
`

const ContactSection = styled.section``

const ContactRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ContactHeader = styled.header`
  color: #fff;
  margin-top: 30px;
  margin-bottom: 30px;
  h2 {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: #fff;
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  p {
    line-height: 2;
  }
  @media (min-width: 1025px) {
    margin-top: 50px;
    margin-bottom: 50px;
    h2 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
    p {
      font-size: 18px;
    }
  }
`

const ContactButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (min-width: 415px) {
    flex-direction: row;
  }
`

const ContactButtonArea = styled.div`
  width: 100%;
  p {
    color: #fff;
    text-align: center;
    margin-bottom: 15px;
  }
  @media (min-width: 415px) {
    width: calc(100% / 2 - 30px);
    margin-bottom: 50px;
  }
`

const ContactButtonContact = styled(Link)`
  position: relative;
  width: 100%;
  display: block;
  padding: 20px 30px;
  color: #fff;
  white-space: nowrap;
  border: none;
  background: linear-gradient(90deg, ${brown} 0%, ${beige} 100%);
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
  transition: transform 0.5s ease;
  @media (min-width: 415px) {
    font-size: 23px;
  }
  &:hover {
    transform: translateY(10px);
  }
`

const ContactButtonStore = styled(Link)`
  position: relative;
  width: 100%;
  display: block;
  padding: 20px 30px;
  color: #fff;
  white-space: nowrap;
  border: none;
  background: linear-gradient(90deg, ${pink} 0%, ${beige} 100%);
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
  transition: transform 0.5s ease;
  @media (min-width: 415px) {
    font-size: 23px;
  }
  &:hover {
    transform: translateY(10px);
  }
`

const Nav = styled.nav`
  background: ${lightBeige};
  padding-top: 30px;
  padding-bottom: 30px;
  h3 {
    color: ${brown};
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 70px;
      background: ${brown};
      margin-top: 23px;
      margin-bottom: 23px;
    }
  }
  @media (min-width: 1025px) {
    h3 {
      font-size: 38px;
      &:after {
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }
  }
  ul {
    li {
      color: ${brown};
      list-style: inside;
      a {
        display: inline;
        @media (min-width: 415px) {
          font-size: 18px;
          line-height: 2;
        }
      }
    }
  }
`

const NavRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const NavMenu = styled.div`
  margin-bottom: 30px;
`

const NavMenuLink = styled(Link)`
  color: ${brown};
`

// const NavStoresLink = styled(Link)`
//   background: #fff;
//   padding: 15px 30px;
//   color: ${brown};
//   margin-bottom: 10px;
//   @media (min-width: 415px) {
//     font-size: 18px;
//   }
// `

const Copyright = styled.div`
  background: ${beige};
`

const CopyrightRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  padding: 30px;
  @media (min-width: 415px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`

const CopyrightSns = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${brown};
  @media (min-width: 415px) {
    justify-content: space-between;
    align-items: center;
  }
  p {
    font-family: 'Crimson Text', serif;
    font-style: italic;
    font-size: 18px;
    @media (min-width: 415px) {
      font-size: 23px;
    }
  }
`

const Icons = styled.div`
  margin-left: 10px;
  a {
    display: inline-block;
    color: ${brown};
  }
  svg {
    width: 23px !important;
    height: 23px !important;
    margin-left: 10px;
    margin-right: 10px;
  }
`

const CopyrightTxt = styled.div`
  color: ${brown};
  font-family: 'Noto Sans JP', sans-serif;
  text-align: center;
  margin-top: 30px;
  @media (min-width: 415px) {
    margin-top: 0;
    font-size: 18px;
  }
`

const Logo = styled.div`
  max-width: 429px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  @media (min-width: 415px) {
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`
const LogoFire = styled.div`
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 60%;
  @media (min-width: 415px) {
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`
const LogoMabo = styled.div`
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  @media (min-width: 415px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
const LogoRinkuu = styled.div`
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  @media (min-width: 415px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const LogoSomeck = styled.div`
  max-width: 320px;
  margin: 2rem auto;
  width: 75%;
  @media (min-width: 415px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`

const LogoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
