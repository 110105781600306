import styled, { css, keyframes } from 'styled-components'

export const SlideBg = keyframes`
  from {
    background-position: 100%;
  }

  to {
    background-position: 0%;
  }
`

export const ZoomBg = keyframes`
  from {
    background-size: 125%;
  }

  50% {
    background-size: 150%;
  }

  to {
    background-size: 125%;
  }
`

export const ZoomImg = keyframes`
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  to {
    transform: scale(1);
  }
`

export const Main = styled.main`
  overflow: hidden;
`

export const Section = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media (min-width: 415px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media (min-width: 1025px) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1380px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding-left: ${padding}rem;
      padding-right: ${padding}rem;
    `}
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}
  ${({ margin }) =>
    margin &&
    css`
      margin-left: -${margin}rem;
      margin-right: -${margin}rem;
    `}
`

export const Col = styled.div`
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  ${({ mobile }) =>
    mobile &&
    css`
      width: calc(100% / 12 * ${mobile});
    `}
  ${({ tablet }) =>
    tablet &&
    css`
      @media (min-width: 415px) {
        width: calc(100% / 12 * ${tablet});
      }
    `}
  ${({ desktop }) =>
    desktop &&
    css`
      @media (min-width: 1025px) {
        width: calc(100% / 12 * ${desktop});
      }
    `}
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop}rem;
    `}
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom}rem;
    `}
  ${({ padding }) =>
    padding &&
    css`
      padding-left: ${padding}rem;
      padding-right: ${padding}rem;
    `}
`
