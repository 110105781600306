import { combineReducers } from 'redux'
import * as actionTypes from '../actions/actionTypes'

const langReducer = (state = 'ja', action) => {
  switch (action.type) {
    case actionTypes.CHANGE_LANG:
      return (state = action.payload.lang)

    default:
      return state
  }
}

const reducer = combineReducers({
  lang: langReducer,
})

export default reducer
