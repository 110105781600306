import React, { Component } from 'react'
import { StaticQuery, graphql, withPrefix, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as actionTypes from '../stores/actions/actionTypes'

class Header extends Component {
  state = {
    showNav: false,
    showOverlay: false,
    colorizeNav: false,
    heroHeight: 0,
  }

  componentDidMount() {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
    this.scrollHandler()
    window.addEventListener('scroll', this.scrollHandler)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('scroll', this.scrollHandler)
  }

  resizeHandler = () => {
    if (window.innerWidth > 1025 && this.state.showNav == false) {
      this.setState({
        showNav: true,
        showOverlay: false,
      })
    } else {
      this.setState({
        showNav: false,
        showOverlay: false,
      })
    }
    this.handleHeroHeight()
    this.handleColorizeNav()
  }

  scrollHandler = () => {
    this.handleHeroHeight()
    this.handleColorizeNav()
  }

  handleNav = () => {
    this.setState(prevState => {
      return {
        showNav: !prevState.showNav,
        showOverlay: !prevState.showOverlay,
      }
    })
  }

  handleLink = () => {
    if (window.screen.width > 1025 && this.state.showNav == false) {
      this.setState({
        showNav: true,
        showOverlay: false,
      })
    } else {
      this.setState({
        showNav: false,
        showOverlay: false,
      })
    }
  }

  handleHeroHeight = () => {
    this.setState({
      heroHeight: document.querySelector('.hero').clientHeight,
    })
  }

  handleColorizeNav = () => {
    if (window.scrollY > this.state.heroHeight) {
      this.setState({
        colorizeNav: true,
      })
    } else {
      this.setState({
        colorizeNav: false,
      })
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allWordpressPage(sort: { fields: wordpress_id }, limit: 5) {
              edges {
                node {
                  title
                  slug
                }
              }
            }
          }
        `}
        render={data => (
          <header>
            <Button
              onClick={() => this.handleNav()}
              showNav={this.state.showNav}
              colorizeNav={this.state.colorizeNav}
            >
              <Bar
                showNav={this.state.showNav}
                colorizeNav={this.state.colorizeNav}
              />
            </Button>
            <Navbar
              key="wjojojwoilfj"
              showNav={this.state.showNav}
              colorizeNav={this.state.colorizeNav}
            >
              <Container>
                <Row>
                  <NavbarBrand>
                    <NavbarLink to="/" onClick={() => this.handleLink()}>
                      <figure>
                        <img
                          src={withPrefix('/img/logo-white.png')}
                          alt="hero"
                          className="pc-only"
                        />
                      </figure>
                      <figure>
                        <img
                          src={withPrefix('/img/logo-pink.png')}
                          alt="hero"
                          className="sp-only"
                        />
                      </figure>
                    </NavbarLink>
                  </NavbarBrand>
                  <NavbarStart>
                    {/* {data.allWordpressPage.edges.map(edge => (
                      <NavbarLink
                        to={edge.node.slug}
                        key={edge.node.slug}
                        onClick={() => this.handleLink()}
                      >
                        <p className="large">{edge.node.title}</p>
                        <p className="small">Sample</p>
                      </NavbarLink>
                    ))} */}
                    <NavbarLink
                      to="/concept"
                      onClick={() => this.handleLink()}
                      lang={this.props.lang}
                    >
                      <p className="large">
                        {this.props.lang == 'ja' && 'こだわり'}
                        {this.props.lang == 'en' && 'CONCEPT'}
                        {this.props.lang == 'zh' && '观念'}
                      </p>
                      <p className="small">
                        {this.props.lang !== 'en' && 'Concept'}
                      </p>
                    </NavbarLink>
                    <NavbarLink
                      to="/stores"
                      onClick={() => this.handleLink()}
                      lang={this.props.lang}
                    >
                      <p className="large">
                        {this.props.lang == 'ja' && '店鋪一覧'}
                        {this.props.lang == 'en' && 'Stores'}
                        {this.props.lang == 'zh' && '店铺内容'}
                      </p>
                      <p className="small">
                        {this.props.lang !== 'en' && 'Stores'}
                      </p>
                    </NavbarLink>
                    {/* <NavbarLink
                      to="/news"
                      onClick={() => this.handleLink()}
                      lang={this.props.lang}
                    >
                      <p className="large">
                        {this.props.lang == 'ja' && 'ニュース'}
                        {this.props.lang == 'en' && 'NEWS'}
                        {this.props.lang == 'zh' && '新闻'}
                      </p>
                      <p className="small">
                        {this.props.lang !== 'en' && 'News'}
                      </p>
                    </NavbarLink> */}
                    <NavbarLink
                      to="/contact"
                      onClick={() => this.handleLink()}
                      lang={this.props.lang}
                    >
                      <p className="large">
                        {this.props.lang == 'ja' && 'お問い合わせ'}
                        {this.props.lang == 'en' && 'CONTACT'}
                        {this.props.lang == 'zh' && '咨询'}
                      </p>
                      <p className="small">
                        {this.props.lang !== 'en' && 'Contact'}
                      </p>
                    </NavbarLink>
                    <NavbarLink
                      to="/recruit"
                      onClick={() => this.handleLink()}
                      lang={this.props.lang}
                    >
                      <p className="large">
                        {this.props.lang == 'ja' && '求人情報'}
                        {this.props.lang == 'en' && 'Recruit'}
                        {this.props.lang == 'zh' && '招募人才'}
                      </p>
                      <p className="small">
                        {this.props.lang !== 'en' && 'Recruit'}
                      </p>
                    </NavbarLink>
                  </NavbarStart>
                  <NavbarIcons>
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                    </a>
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={['fab', 'instagram']} />
                    </a>
                    <a href="" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={['fab', 'line']} />
                    </a>
                  </NavbarIcons>
                  <NavbarEnd>
                    {!(this.props.lang == 'ja') && (
                      <NavbarLangJa onClick={() => this.props.changeLang('ja')}>
                        <NavbarLang>
                          <p className="en">JA</p>
                          <p className="ja">日本語</p>
                        </NavbarLang>
                      </NavbarLangJa>
                    )}
                    {/* {!(this.props.lang == 'en') && (
                      <NavbarLangEn onClick={() => this.props.changeLang('en')}>
                        <NavbarLang>
                          <p className="en">EN</p>
                          <p className="ja">英語</p>
                        </NavbarLang>
                      </NavbarLangEn>
                    )} */}
                    {!(this.props.lang == 'zh') && (
                      <NavbarLangZh onClick={() => this.props.changeLang('zh')}>
                        <NavbarLang>
                          <p className="en">ZH</p>
                          <p className="ja">中国語</p>
                        </NavbarLang>
                      </NavbarLangZh>
                    )}
                    {/* <NavbarA
                      href="https://github.com/GatsbyCentral/gatsby-starter-wordpress"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon>
                        <img src={github} alt="Github" />
                      </Icon>
                    </NavbarA> */}
                  </NavbarEnd>
                </Row>
              </Container>
            </Navbar>
            <Overlay
              showOverlay={this.state.showOverlay}
              onClick={() => this.handleNav()}
            />
          </header>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
})

const mapDispatchToProps = dispatch => ({
  changeLang: lang => {
    dispatch({ type: actionTypes.CHANGE_LANG, payload: { lang } })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)

const pink = '#e3007f'
const beige = '#fadcbe'
const lightBeige = '#FEF8F2'
const brown = '#603813'

const Overlay = styled.div`
  pointer-events: none;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  height: 100vh;
  width: 100%;
  z-index: 4;
  transition: background 0.6s;
  ${props =>
    props.showOverlay &&
    css`
      pointer-events: fill;
      background: rgba(0, 0, 0, 0.6);
    `}
`

const Navbar = styled.nav`
  background: ${lightBeige};
  position: fixed;
  width: 80%;
  height: 100%;
  z-index: 99;
  left: 100%;
  transition: left 0.5s ease, background 0.5s ease;
  ${props =>
    props.showNav &&
    css`
      left: 20%;
    `}
  @media (min-width: 600px) {
    width: 300px;
    ${props =>
      props.showNav &&
      css`
        left: calc(100% - 300px);
      `}
  }
  @media (min-width: 1025px) {
    background: none;
    position: fixed;
    left: 0;
    width: 100%;
    height: auto;
    ${props =>
      props.showNav &&
      css`
        left: 0;
      `}
    ${props =>
      props.colorizeNav &&
      css`
        background: rgba(0, 0, 0, 0.9);
      `}
  }
  .sp-only {
    display: block;
    @media (min-width: 1025px) {
      display: none;
    }
  }
  .pc-only {
    display: none;
    @media (min-width: 1025px) {
      display: block;
    }
  }
`

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  @media (min-width: 1025px) {
    height: auto;
  }
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  @media (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
    height: auto;
  }
`

const Button = styled.div`
  display: inline-block;
  content: '';
  z-index: 999;
  padding: 22.5px 25px;
  position: fixed;
  top: 0;
  right: 0;
  transition: background 0.5s ease;
  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 3.75px;
    background: #fff;
    transition: top 0.5s ease, transform 0.5s ease, background 0.5s ease;
  }
  &:before {
    top: 33.33333333%;
    ${props =>
      props.colorizeNav &&
      css`
        background: ${brown};
      `}
    ${props =>
      props.showNav &&
      css`
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: ${brown};
      `}
  }
  &:after {
    top: 66.66666667%;
    ${props =>
      props.colorizeNav &&
      css`
        background: ${brown};
      `}
    ${props =>
      props.showNav &&
      css`
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background: ${brown};
      `}
  }
  @media (min-width: 1025px) {
    display: none;
  }
  ${props =>
    props.showNav &&
    css`
      background: none;
    `}
`

const Bar = styled.div`
  position: absolute;
  content: '';
  display: block;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
  height: 3.75px;
  background: #fff;
  transition: opacity 0.5s ease, transform 0.5s ease, background 0.5s ease;
  ${props =>
    props.colorizeNav &&
    css`
      background: #603813;
    `}
  ${props =>
    props.showNav &&
    css`
      opacity: 0;
      transform: translate(-100%, -50%);
      background: #603813;
    `}
`

const NavbarBrand = styled.div`
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem;
  margin-top: 45px;
  @media (min-width: 1025px) {
    margin-top: 0;
    figure {
      max-width: 200px;
    }
  }
`

const NavbarStart = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1025px) {
    flex-direction: row;
    justify-content: flex-start;
    margin-right: auto;
  }
`

const NavbarEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 1025px) {
    margin-left: auto;
    margin-top: 0;
  }
`

const NavbarLink = styled(Link)`
  display: flex;
  align-items: center;
  line-height: 1.5;
  padding: 15px 30px;
  color: #603813;
  .large {
    font-size: 15px;
  }
  .small {
    position: relative;
    font-size: 10px;
    margin-left: 10px;
    &::before {
      ${props =>
        props.lang !== 'en' &&
        css`
          content: '－';
          display: inline;
          margin-right: 10px;
        `}
    }
  }

  @media (min-width: 1025px) {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    .large {
      font-size: 15px;
    }
    .small {
      font-size: 12px;
      margin-left: 0;
      &::before {
        content: none;
      }
    }
    &:hover {
      opacity: 0.5;
    }
  }
`

const NavbarA = styled.a`
  display: flex;
  align-items: center;
  line-height: 1.5;
  padding: 15px 30px;
`

const NavbarLangJa = styled.button`
  position: relative;
  width: 50%;
  padding-top: 80px;
  color: #fff;
  background: ${pink};
  transition: opacity 0.5s ease;
  border: none;
  @media (min-width: 1025px) {
    width: 84.17px;
    padding-top: 84.17px;
    &:hover {
      opacity: 0.5;
    }
  }
`

const NavbarLangEn = styled.button`
  position: relative;
  width: 50%;
  padding-top: 80px;
  color: ${beige};
  background: ${brown};
  transition: opacity 0.5s ease;
  border: none;
  @media (min-width: 1025px) {
    width: 84.17px;
    padding-top: 84.17px;
    &:hover {
      opacity: 0.5;
    }
  }
`
const NavbarLangZh = styled.button`
  position: relative;
  width: 50%;
  padding-top: 80px;
  color: ${brown};
  background: ${beige};
  transition: opacity 0.5s ease;
  border: none;
  @media (min-width: 1025px) {
    width: 84.17px;
    padding-top: 84.17px;
    &:hover {
      opacity: 0.5;
    }
  }
`
const NavbarLang = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .ja {
    font-size: 12px;
  }
`

const NavbarIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 30px;
  @media (min-width: 1025px) {
    display: none;
  }
  a {
    display: inline-block;
    color: ${brown};
    margin-left: 15px;
    margin-right: 15px;
  }
  svg {
    width: 30px !important;
    height: 30px !important;
  }
`
