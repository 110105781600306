import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    color: #000;
    background: #fff;
    line-height: 1.4;
  }

  .clearfix::after {
    content: '';
    display: block;
    clear: both;
  }

  li {
    list-style: none;
  }

  a,
  a:hover {
    -webkit-tap-highlight-color: transparent;
    /* iOS highlight color */
    text-decoration: none;
    outline: none;
  }

  a {
    display: block;
    transition-property: all;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: auto;
  }

  a:hover {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: auto;
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  video {
    max-width: 100%;
    height: auto;
  }

  hr {
    height: 0;
    border: 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #fff;
  }

  img {
    display: block;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }

  label {
    font-size: 15px;
  }

  body {
    font-family: source-han-serif-japanese, source-han-serif-sc, serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4;
    font-size: 15px;
  }
  
  body:lang(zh) {
      font-family: source-han-serif-sc, source-han-serif-japanese, 'KaiTi', 'NSimSun', 'SimSun', 'Microsoft YaHei', '微软雅黑', STXihei, '华文细黑', serif;
  }

  h1 {
    font-size: 23px;
    font-weight: bold;
  }

  h2 {
    font-size: 23px;
    font-weight: bold;
  }

  h3 {
    font-size: 21px;
    font-weight: bold;
  }

  h4 {
    font-size: 15px;
    font-weight: bold;
  }

  h5 {
    font-size: 12px;
    font-weight: bold;
  }

  h6 {
    font-size: 10px;
    font-weight: bold;
  }

  p {
    font-size: 15px;
  }

  a {
    font-size: 15px;
  }

  @media (min-width: 415px) {
    body {
        line-height: 1.4;
        font-size: 15px;
      }
      
      h1 {
        font-size: 36px;
        font-weight: bold;
      }
      
      h2 {
        font-size: 30px;
        font-weight: bold;
      }
      
      h3 {
        font-size: 23px;
        font-weight: bold;
      }
      
      h4 {
        font-size: 18px;
        font-weight: bold;
      }
      
      h5 {
        font-size: 15px;
        font-weight: bold;
      }
      
      h6 {
        font-size: 12px;
        font-weight: bold;
      }
      
      p {
        font-size: 15px;
      }
      
      a {
        font-size: 15px;
      }
  }
`
