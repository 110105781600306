import React from 'react'
import Helmet from 'react-helmet'
// import { StaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faInstagram,
  faLine,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

import Reset from './reset'
import Header from './header'
import Loader from './loader'
import Transition from './transition'
import Footer from './footer'

library.add(
  faFacebookF,
  faInstagram,
  faLine,
  faTwitter,
  faChevronLeft,
  faChevronRight
)

const App = ({ location, children, title, lang }) => {
  return (
    <>
      <Helmet
        title={title}
        meta={[
          {
            name: 'description',
            content:
              '芙蓉苑には本場四川の食文化を大切に、本物の四川料理の味を日本に伝える使命があります。正真正銘「本物の四川料理」の美味しさを大切に「笑顔」を添えてお客様にお届けすることをお約束致します。',
          },
        ]}
      >
        <html lang={lang} />
      </Helmet>
      <Reset />
      <Loader location={location} />
      <Header location={location} />
      <Transition location={location}>
        <main>{children}</main>
      </Transition>
      <Footer />
    </>
  )
}

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(App)
