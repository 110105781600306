import React from 'react'
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group'
import styled from 'styled-components'

// const pink = '#e3007f'
const beige = '#fadcbe'
const brown = '#603813'

// This variable will be responsible for our animation duration
const timeoutFirst = 450
const timeoutSecond = 550

const LoadingFirst = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 150vw;
  height: 100vh;
  background: ${beige};
  z-index: 100;
`

const LoadingSecond = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 150vw;
  height: 100vh;
  background: ${brown};
  z-index: 101;
`

// This object contains basic styles for animation, but you can extend them to whatever you like. Be creative!
const getTransitionStyles1 = {
  entering: {
    position: 'fixed',
    left: '100%',
    opacity: 0,
  },
  entered: {
    transition: `left 1000ms ease-in-out`,
    left: '-200%',
    opacity: 1,
  },
  exiting: {
    transition: `left 1000ms ease-in-out`,
    left: '-200%',
    opacity: 0,
  },
}

const getTransitionStyles2 = {
  entering: {
    position: 'fixed',
    left: '100%',
    opacity: 0,
  },
  entered: {
    transition: `left 1000ms ease-in`,
    left: '-200%',
    opacity: 1,
  },
  exiting: {
    transition: `left 1000ms ease-in`,
    left: '-200%',
    opacity: 0,
  },
}

const Loader = ({ location }) => (
  <div>
    <TransitionGroup>
      <ReactTransition
        // the key is necessary here because our ReactTransition needs to know when pages are entering/exiting the DOM
        key={location.pathname}
        // duration of transition
        timeout={{
          enter: timeoutFirst,
          exit: timeoutFirst,
        }}
      >
        {// Application of the styles depending on the status of page(entering, exiting, entered) in the DOM
        status => (
          <LoadingFirst
            style={{
              ...getTransitionStyles1[status],
            }}
          />
        )}
      </ReactTransition>
    </TransitionGroup>
    <TransitionGroup>
      <ReactTransition
        // the key is necessary here because our ReactTransition needs to know when pages are entering/exiting the DOM
        key={location.pathname}
        // duration of transition
        timeout={{
          enter: timeoutSecond,
          exit: timeoutSecond,
        }}
      >
        {// Application of the styles depending on the status of page(entering, exiting, entered) in the DOM
        status => (
          <LoadingSecond
            style={{
              ...getTransitionStyles2[status],
            }}
          />
        )}
      </ReactTransition>
    </TransitionGroup>
  </div>
)

export default Loader
