import React from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import reducer from '../stores/reducers'

import App from '../components/app'

import 'sanitize.css'
// import 'sanitize.css/forms.css'
import 'sanitize.css/typography.css'

const store = createStore(reducer)

const Index = props => {
  return (
    <Provider store={store}>
      <App {...props} />
    </Provider>
  )
}

export default Index
